import { Image } from 'antd';
import gift from '../Images/Gift.png'
import home from '../Images/Home.png'
import win from '../Images/Trophy.png'
import voucher from '../Images/History.png'
import logo from '../Images/OTT_grey.png'

export const GiftIcon: React.FC = () => {
    return (
        <>
            <Image
                preview={false}
                style={{
                    height: '2rem',
                    width: '2rem'
                }}
                src={gift}
            />
        </>
    );
};

export const HomeIcon: React.FC = () => {
    return (
        <>
            <Image
                preview={false}
                style={{
                    height: '2rem',
                    width: '2rem'
                }}
                src={home}
            />
        </>
    );
};

export const WinIcon: React.FC = () => {
    return (
        <>
            <Image
                preview={false}
                style={{
                    height: '2rem',
                    width: '2rem'
                }}
                src={win}
            />
        </>
    );
};

export const VouchersIcon: React.FC = () => {
    return (
        <>
            <Image
                preview={false}
                style={{
                    height: '2rem',
                    width: '2rem',
                    marginLeft: '.7rem'
                }}
                src={voucher}
            />
        </>
    );
};

export const Logo: React.FC = () => {

    return (
        <>
            <Image
                preview={false}
                style={{
                    height: '2rem',
                    width: '2rem'
                }}
                src={logo}
            />
        </>
    );
};