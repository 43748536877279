import React from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";

import "./index.css";
import ReactDOM from "react-dom";

const UnsecuredPage = () => (
  <div>
    <h1>If you see this page, link you have clicked on is not secure.</h1>
    <h2>Please inform team with the reference of the application from where you clicked this link.</h2>
    <h2>Click <a href={window.self.location.href} target='_blank' rel="noreferrer">here</a> to access WebApp safely.</h2>
  </div>
);

if(window.self === window.top) {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'));
} else{
  ReactDOM.render(<UnsecuredPage />, document.getElementById('root'));
}