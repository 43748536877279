import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';


const AuthCheckRoute: React.FC = () => {
    const navigate = useNavigate();
    const [unAuthenticated, setUnAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    const adminRoles = [
        'admin',
        'super-admin',
        'support'
    ]

    useEffect(() => {
        const token = localStorage.getItem('token');
        const user_type = localStorage.getItem('user_type');
        token && navigate((adminRoles.includes(user_type)) ? '/admin/dashboard' : '/dashboard'); setLoading(false); setUnAuthenticated(true)
    }, [navigate]);

    if (loading) {
        return <Spin indicator={<div className='global_loader' ></div>} style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />;
    }

    return (
        <>
            {unAuthenticated ? <Outlet /> : <Navigate to="/" />}
        </>
    );
};

export default AuthCheckRoute;
