
// project import

import { adminRoutes } from "./AdminRoutes"
import { authRoutes } from "./AuthRoutes"
import { frontRoutes } from "./FrontRoutes"
import { openRoutes } from "./OpenRoutes"


// ==============================|| ROUTING RENDER ||============================== //

const routes = [
    {
        path: '/',
        children: [
            authRoutes,
            frontRoutes,
            adminRoutes,
            openRoutes,
        ]
    }
]

export default routes