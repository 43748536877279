import { RouteObject } from 'react-router-dom';
import {
    LoginForm,
    Register,
    Forgotpassword,
    Success,
    Adminlogin,
    ResetPassword,
    NewPassword
  } from './components'
import AuthCheckRoute from './AuthCheckRoute';

const authRoutes: RouteObject = {
  path: '/',
  element: <AuthCheckRoute />,
  children:
  [
    { 
      path: "",
      element: <LoginForm />
    },
    {
      path: "admin/login",
      element: <Adminlogin />
    },
    {
      path: "success-password",
      element: <Success />
    },
    {
      path: "register",
      element: <Register />
    },
    {
      path: "forget-password",
      element: <Forgotpassword />
    },
    {
      path: "reset-password/:token",
      element: <ResetPassword />
    },
    {
      path: "new-password",
      element: <NewPassword />
    },
  ]
}

export { authRoutes };