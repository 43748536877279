import React from "react";
import Sidebar from "./Sidebar/Navigation";
import Header from "./Header/Header";
import { Outlet, useLocation } from "react-router-dom";
import { Layout } from "antd";

const { Content } = Layout;

const Master: React.FC = () => {
  const title = [
    { id: "/admin/dashboard", name: "OTT 4 Me Dashboard" },
    { id: "/admin/voucher", name: "OTT Voucher Dashboard" },
    { id: "/admin/coupons", name: "OTT Coupon Dashboard" },
    { id: "/admin/transactions", name: "OTT Transactions Dashboard" },
    { id: "/admin/collect", name: "OTT Collect Dashboard" },
    { id: "/admin/payout/dashboard", name: "OTT Payout Dashboard" },
    { id: "/admin/payout/providers", name: "OTT Payout Providers" },
    { id: "/admin/secondchance/competition", name: "Second Chance Competition" },
    { id: "/admin/secondchance/competition/:competitionID", name: "Second Chance Competition Entries" },
    { id: "/admin/secondchance/spin-win", name: "Second Chance Spin-Win" },
    { id: "/admin/secondchance/surveys", name: "Second Chance Surveys" },
    { id: "/admin/secondchance/sponsors", name: "Second Chance Sponsors" },
    { id: "/admin/secondchance/sponsor", name: "Second Chance Sponsors" },
    { id: "/admin/users/user", name: "User Dashboard" },
    { id: "/admin/users/admin", name: "Admin Dashboard" },
    { id: "/admin/roles", name: "Roles Dashboard" },
    { id: "/admin/notifications/view", name: "Notifications Dashboard" },
    { id: "/admin/notifications/create", name: "Create Notification" },
    { id: "/admin/notifications/edit", name: "Edit Notification" },
    { id: "/admin/settings/designer", name: "Page Designer" },
    { id: "/admin/settings/library", name: "Media Library" },
    { id: "/admin/settings", name: "Settings" }
  ];

  const location = useLocation();
  const url = location.pathname;
  const selectedTitle = title.find((title) => url.startsWith(title.id))
  const titleName = selectedTitle ? selectedTitle.name : "OTT 4 Me Dashboard";

  return (
    <>
      <Layout style={{width: '100vw'}}>
        <Header title={titleName} />
        <Content style={{width: '100vw', height: '100vh'}}>
          <Layout>
            <Sidebar />
              <Content style={{padding: '100px 20px 20px 20px', height: '100vh', overflow: 'auto'}}>
              <Outlet />
            </Content>
          </Layout>
        </Content>
      </Layout>
    </>
  );
};

export default Master;
