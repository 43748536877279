import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import "./Header.css";

interface HeaderProps {
  title: string | null;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  const [username, setUsername] = useState<string>('');

  useEffect(() => {
    const storedUser = localStorage.getItem('name');
    setUsername(storedUser || 'Username');
  }, []);

  return (
    <div className="header">
      <span className='header-left'>{title}</span>
      <div className="header-right">
        <Avatar
          size={27}
          icon={<UserOutlined />}
          className="avatar"
        />
        <span className='user'>
          {`Hello, ${username || 'Username'}`}
        </span>
      </div>
    </div>

  );
};

export default Header;
