import React from 'react';

const Logo = () => {

    return (
        <>
            <svg width="180" height="45" viewBox="0 0 180 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_142_2244)">
                    <path d="M22.2986 0C10.0024 0 0 10.0912 0 22.4967C0 30.7538 4.47284 38.3321 11.6694 42.2785C11.6891 42.2885 11.7088 42.2984 11.7252 42.3083C11.8532 42.3779 11.9812 42.4474 12.1125 42.5136C12.1518 42.5335 12.1945 42.5567 12.2339 42.5765C12.375 42.6494 12.5194 42.7189 12.6605 42.7884C12.667 42.7917 12.6769 42.795 12.6835 42.8017C12.8475 42.8811 13.0116 42.9573 13.1757 43.0334C13.1921 43.04 13.2085 43.0466 13.2249 43.0566C13.3857 43.1294 13.5465 43.1989 13.7073 43.2685C13.7073 43.2685 13.7106 43.2685 13.7139 43.2685C14.2357 43.487 14.764 43.6889 15.3055 43.8677L22.8302 9.8959H9.2476C12.5391 6.42621 17.1727 4.26096 22.2986 4.26096C27.4245 4.26096 32.0581 6.42621 35.3496 9.8959H26.6336L18.6724 44.6987C19.8505 44.8941 21.0581 44.9967 22.2887 44.9967C22.7941 44.9967 23.2962 44.9768 23.795 44.9437C23.9033 44.9371 24.0116 44.9272 24.1166 44.9205C24.2216 44.9106 24.3233 44.904 24.4284 44.8941C24.5662 44.8808 24.7007 44.8676 24.8386 44.851C24.8714 44.8477 24.9009 44.8444 24.9337 44.8411C26.0659 44.7053 27.1816 44.4835 28.2777 44.1789C32.8818 42.8877 37.0331 40.0636 39.9668 36.2265C42.9958 32.2635 44.5972 27.5158 44.5972 22.5C44.6005 10.0912 34.5948 0 22.2986 0ZM15.1972 16.0672L10.6653 36.4549C6.62559 33.0283 4.22344 27.9429 4.22344 22.4967C4.22344 20.2354 4.63693 18.0669 5.38513 16.0639H15.1939L15.1972 16.0672ZM36.6196 33.6209C34.2404 36.733 30.8767 39.0241 27.1455 40.067C26.7517 40.1762 26.358 40.2722 25.9576 40.355L31.5002 16.0639H39.2088C39.9603 18.0636 40.3705 20.2321 40.3705 22.4967C40.3705 26.5623 39.0742 30.4094 36.6196 33.6209Z" fill="#46FF6D" />
                    <path d="M67.2141 10.7732C69.8919 10.7732 71.9725 11.5446 73.4623 13.0874C74.9489 14.6302 75.6938 16.7822 75.6938 19.5467C75.6938 22.3112 75.1819 24.8374 74.158 27.1284C73.1342 29.4195 71.7198 31.1742 69.9182 32.3958C68.1166 33.6175 66.0262 34.2267 63.647 34.2267C60.8773 34.2267 58.7377 33.452 57.2282 31.9058C55.7186 30.3564 54.9639 28.2077 54.9639 25.4532C54.9639 22.6986 55.4955 20.1692 56.5554 17.8649C57.6154 15.5573 59.0593 13.7992 60.8872 12.5908C62.715 11.3824 64.8218 10.7765 67.2109 10.7765L67.2141 10.7732ZM69.5933 19.6096C69.5933 18.4244 69.3472 17.4974 68.8517 16.8286C68.3561 16.1598 67.6408 15.8221 66.7055 15.8221C65.6849 15.8221 64.7496 16.2591 63.8931 17.1299C63.0399 18.0039 62.3574 19.2024 61.8454 20.7287C61.3368 22.2549 61.0808 23.8209 61.0808 25.42C61.0808 27.9329 62.1112 29.191 64.1688 29.191C65.1467 29.191 66.0557 28.7606 66.8958 27.8965C67.7359 27.0357 68.3955 25.8703 68.8714 24.4069C69.3505 22.9436 69.59 21.3412 69.59 19.6063L69.5933 19.6096Z" fill="white" />
                    <path d="M85.463 33.9156H79.4248L83.0871 16.1665H78.1909L79.2574 11.1342H95.1372L94.0411 16.1665H89.1285L85.4663 33.9156H85.463Z" fill="white" />
                    <path d="M103.279 33.9156H97.2407L100.903 16.1665H96.0068L97.0734 11.1342H112.953L111.857 16.1665H106.944L103.282 33.9156H103.279Z" fill="white" />
                    <path d="M125.482 33.9155L126.624 28.6315H116.445L116.891 26.2941L129.821 11.1143H132.771L129.621 26.3239H133.158L132.663 28.6315H129.095L127.967 33.9155H125.479H125.482ZM119.549 26.3272H127.104L128.354 20.4043C128.528 19.6163 128.718 18.8085 128.925 17.9808C129.132 17.1564 129.335 16.3718 129.542 15.6368C129.749 14.8985 129.919 14.2794 130.05 13.7827H129.942C129.663 14.2098 129.302 14.723 128.862 15.3256C128.419 15.9281 128.045 16.4049 127.734 16.7591L119.546 26.3272H119.549Z" fill="#46FF6D" />
                    <path d="M141.589 33.9156L146.36 11.1309H150.052L152.398 30.0354H152.523L162.84 11.1309H166.841L162.145 33.9156H159.519L162.486 19.7654C162.67 18.9145 162.85 18.1133 163.028 17.365C163.201 16.6168 163.366 15.9348 163.513 15.3157C163.661 14.6966 163.799 14.1701 163.923 13.7331H163.861L152.74 33.9156H150.685L148.122 13.7497H147.998C147.955 14.1768 147.886 14.7197 147.788 15.3786C147.689 16.0374 147.578 16.7393 147.446 17.4809C147.318 18.2225 147.181 18.9377 147.036 19.623L144.057 33.9123H141.585L141.589 33.9156Z" fill="#46FF6D" />
                    <path d="M172.879 34.2268C171.612 34.2268 170.52 33.9685 169.604 33.4553C168.688 32.9422 167.983 32.2039 167.494 31.2438C167.005 30.2836 166.759 29.1315 166.759 27.7906C166.759 26.4498 166.956 25.0493 167.353 23.7151C167.75 22.3808 168.318 21.1691 169.059 20.0831C169.801 18.9972 170.693 18.1331 171.74 17.4875C172.784 16.8419 173.955 16.5208 175.255 16.5208C176.81 16.5208 177.988 16.8717 178.792 17.5736C179.596 18.2755 179.997 19.2389 179.997 20.4639C179.997 21.3247 179.8 22.1226 179.403 22.8476C179.006 23.576 178.399 24.2051 177.582 24.7414C176.761 25.2777 175.727 25.6916 174.477 25.9896C173.227 26.2842 171.747 26.4332 170.037 26.4332H169.45C169.43 26.6186 169.41 26.8272 169.397 27.0556C169.381 27.2841 169.374 27.496 169.374 27.6946C169.374 29.0653 169.702 30.138 170.362 30.9127C171.022 31.6874 172.009 32.0748 173.329 32.0748C174.142 32.0748 174.91 31.9589 175.639 31.7238C176.367 31.4887 177.125 31.1808 177.916 30.7968V33.0249C177.155 33.3792 176.387 33.6672 175.616 33.8891C174.845 34.1109 173.932 34.2234 172.882 34.2234L172.879 34.2268ZM169.821 24.3143H170.191C171.511 24.3143 172.718 24.1984 173.821 23.9634C174.923 23.7283 175.809 23.3443 176.479 22.8112C177.148 22.2749 177.483 21.5631 177.483 20.6691C177.483 20.0865 177.286 19.6097 176.896 19.2356C176.505 18.8615 175.908 18.6761 175.104 18.6761C174.392 18.6761 173.693 18.8979 173.004 19.3382C172.315 19.7786 171.688 20.4208 171.127 21.2618C170.565 22.1027 170.129 23.1224 169.821 24.3176V24.3143Z" fill="#46FF6D" />
                </g>
                <defs>
                    <clipPath id="clip0_142_2244">
                        <rect width="180" height="45" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};

export default Logo;