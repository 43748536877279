import axios from 'axios';
import dayjs from 'dayjs';
import { TempPrize } from './Types';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const registerUser = async (userData: {
    first_name: string;
    last_name: string;
    email: string;
    mobile: string;
    password: string;
    password_confirmation: string;
}) => {
    try {
        const response = await axios.post('/register', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const loginUser = async (userData: {
    email?: string;
    password: string;
    mobile?: string;
    type?: string;
}) => {
    try {
        const response = await axios.post('/login', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getUser = async () => {
    try {
        const response = await axios.get('/user',);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const googleLogin = async (userData: {
    email: string;
    name: string;
    sub: string;
}) => {
    try {
        const response = await axios.post('/auth/google', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const sendEmail = async (userData: {
    email: string;
}) => {
    try {
        const response = await axios.post('/forgot-password', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const changePassword = async (userData: {
    current_password: string,
    new_password: string
}) => {
    try {
        const response = await axios.post('change-password', userData);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updatePassword = async (userData: {
    password: string,
    password_confirmation: string
},
    token: string,
    email: string
) => {
    try {
        const requestBody = {
            ...userData,
            token,
            email,
        };
        const response = await axios.post('/update-password', requestBody);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const CheckResetPwdToken = async (token: any) => {
    try {
        const response = await axios.post(`/reset-password/${token}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const adminlogin = async (userData: {
    email: string;
    password: string;
}) => {
    try {
        const response = await axios.post('/admin/login', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeMobile = async (userData: {
    mobile: string;
}) => {
    try {
        const response = await axios.post('/user/mobile/remove', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addMobile = async (userData: {
    mobile: string;
}) => {
    try {
        const response = await axios.post('/user/mobile/add', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const userUpdate = async (userData: {
    first_name: string,
    last_name: string,
    id_number: string,
    passport_number: string,
    date_of_birth: string,
    gender: string,
    citizenship: string,
    country_of_issue: string
    email: string
}) => {
    try {
        const response = await axios.post('/user/update', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const privateroute = async () => {
    try {
        const response = await axios.get('/user');
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const CreatePayoutVoucher = async (userData: {
    provider_id: string;
    amount: string;
    user_mobile_id: string;
    payment_type: string;
    payment_method: string;
}) => {
    try {
        const response = await axios.post('/create-payout-voucher', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const   CreateOttVoucher  = async (userData: {
    // provider_id: string;
    amount: string;
    user_mobile_id: string;
    payment_type: string;
    payment_method: string;
}) => {
    try {
        const response = await axios.post('/create-ott-voucher', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const transaction = async () => {
    try {
        const response = await axios.get('/user/vouchers');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const checkvoucher = async (userData: {
    uniqueReference: string;
}) => {
    try {
        const response = await axios.post('/ott/check-voucher', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const voucherstatic = async () => {
    try {
        const response = await axios.get('/ott/voucher-statistics');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const adminvouchers = async (CurrentPage: any) => {
    try {
        const response = await axios.get(`/admin/ott/vouchers?page=${CurrentPage}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const adminlogout = async (token: any) => {
    try {
        const response = await axios.post('/admin/logout', token);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const userlogout = async (token: any) => {
    try {
        const response = await axios.post('/user/logout', token);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const allproviders = async () => {
    try {
        const response = await axios.get('/admin/get-all-providers');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const imageupload = async (formData: any) => {
    try {
        const response = await axios.post('/admin/providers-image-upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const uploadCustomProviderBanner = async (id: number, formData: any) => {
    try {
        const response = await axios.post(`/admin/custom-providers/${id}/image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const payoutproviders = async () => {
    try {
        const response = await axios.get('/ott/get-payout-providers');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getverifymobile = async () => {
    try {
        const response = await axios.post('/get-verify-mobile');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const verifymobile = async (userData: {
    mobile: any;
    verification_code: any;
}) => {
    try {
        const response = await axios.post('/verify-mobile', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const resendotp = async (userData: {
    mobile: any;
}) => {
    try {
        const response = await axios.post('/resend-otp', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const uploadDoc = (file: File): Promise<any> => {
    let formData = new FormData();

    formData.append("image", file);

    return axios.post("/user/image-upload", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
};
export const removeDoc = async () => {
    try {
        const response = await axios.post('/user/image-remove');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createNotification = (fileList: any[], notification: {
    subject: string,
    user: Array<any>,
    // email_message: string,
    in_app_message: string,
    sms_message: string,
    email_message: string,
    scheduled_notifications: string,
}): Promise<any> => {

    let formData = new FormData();

    formData.append("subject", notification.subject);

    // for (let index = 0; index < file.length; index++) {
    //     formData.append("message_images["+index+"]", file[index]);
    // }
    formData.append("email_message", notification.email_message);
    formData.append("sms_message", notification.sms_message);
    formData.append("in_app_message", notification.in_app_message);
    if (notification.scheduled_notifications === "") {
        //set curr
        formData.append("scheduled_notifications", dayjs().format('YYYY-MM-DD HH:mm:ss'));
    } else {
        formData.append("scheduled_notifications", notification.scheduled_notifications);
    }
    for (let index = 0; index < notification.user.length; index++) {
        formData.append("user[" + index + "]", notification.user[index].id);
    }

    for (let index = 0; index < fileList.length; index++) {
        const file = fileList[index];
        formData.append(`message_images[${index}]`, file.originFileObj);
    }


    return axios.post("/admin/send-notification", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "x-rapidapi-host": "file-upload8.p.rapidapi.com",
            "x-rapidapi-key": "your-rapidapi-key-here",
        }
    });
};

export const EditNotificationadmin = (fileList: any[], notification: {
    notification_id: string,
    subject: string,
    user: Array<any>,
    sms_message: string,
    in_app_message: string,
    email_message: string,
    scheduled_notifications: string,
}): Promise<any> => {
    let formData = new FormData();
    formData.append("notification_id", notification.notification_id);
    formData.append("subject", notification.subject);
    formData.append("in_app_message", notification.in_app_message);
    formData.append("email_message", notification.email_message);
    formData.append("sms_message", notification.sms_message);
    if (notification.scheduled_notifications === "") {
        formData.append("scheduled_notifications", dayjs().format('YYYY-MM-DD HH:mm:ss'));
    } else {
        formData.append("scheduled_notifications", notification.scheduled_notifications);
    }
    for (let index = 0; index < notification.user.length; index++) {
        formData.append("user[" + index + "]", notification.user[index].id);
    }
    for (let index = 0; index < fileList.length; index++) {
        const file = fileList[index];
        formData.append(`message_images[${index}]`, file.originFileObj);
    }
    return axios.post("/admin/send-notification", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "x-rapidapi-host": "file-upload8.p.rapidapi.com",
            "x-rapidapi-key": "your-rapidapi-key-here",
        }
    });
};

export const usernotification = async () => {
    try {
        const response = await axios.post('/user/internal-notification');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const notificationupdate = async (userData: {
    id: any;
    is_read: any;
}) => {
    try {
        const response = await axios.post('/user/internal-notification/update', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const adminnotifications = async (CurrentPage: any) => {
    try {
        const response = await axios.post(`/admin/get-notification-list?page=${CurrentPage}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const adminnotificationlist = async () => {
    try {
        const response = await axios.post('/admin/get-notification-list');
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const admingetusernotification = async () => {
    try {
        const response = await axios.post('/admin/get-user-notification');
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const filterNotification = async (filterNotificationData:
    {
        country: string,
        provider: Array<any>,
        voucherGreaterThanOrEqualAmount: string,
        voucherLessThanOrEqualAmount: string,
    }, page: any
) => {
    try {

        let data = new FormData();
        if(filterNotificationData.country != ''){
            data.append('filter[country]', filterNotificationData.country);
        }

        for (let index = 0; index < filterNotificationData.provider.length; index++) {
            data.append("filter[provider][" + index + "]", filterNotificationData.provider[index]);
        }
        if(filterNotificationData.voucherGreaterThanOrEqualAmount != ''){
            data.append('filter[voucher_amount_greater]', filterNotificationData.voucherGreaterThanOrEqualAmount);
        }
        if(filterNotificationData.voucherLessThanOrEqualAmount != ''){
            data.append('filter[voucher_amount_less]', filterNotificationData.voucherLessThanOrEqualAmount);
        }

        return axios.post(`/admin/get-user-notification?page=${page}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });

    } catch (error) {
        throw error;
    }
};


export const notificationdelete = async (userData: {
    notification_id: any;
}) => {
    try {
        const response = await axios.post('/admin/notification/remove', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};


// list of all competitions admin side
export const getallcompetition = async (PageNumber: any, PageSize: any, search: any , searchData : any) => {
    try {
        const response = await axios.post(`/admin/ott4me/get-all-competitions?page=${PageNumber}&per_page=${PageSize}&search=${search}&filter=${searchData}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}


// get competition details admin side
export const getallcompetitionentries = async (
    competitionID: any,
    // pageNumber: any
) => {
    try {
        const response = await axios.post(`/ott4me/get-competitions-entries/?competitionID=${competitionID}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}


// list of all competitions user side
export const getallcompetitionuser = async (page: any, per_page: any) => {
    try {
        const response = await axios.get(`/get-user-competitions?page=${page}&per_page=${per_page}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}


// post competition img that will be shown on the user side
export const competitionimgupload = async (competitionID: any, banner_type: String, competition_image: any) => {
    try {
        const response = await axios.post(`/ott4me/competitions/image-upload`, { competitionID, banner_type, competition_image },
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const competitionbannerupload = async (competitionID: string, banner_type: string, banner: any) => {
    const formData = new FormData();
    formData.append('competitionID', competitionID);
    formData.append('banner_type', banner_type);
    formData.append('banner', banner);

    try {
        const response = await axios.post('/ott4me/competitions/banner-upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Ensure the correct content type
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};


// publish competition
export const competitionpublish = async (competitionID: any, published: any) => {
    try {
        const response = await axios.post(`/ott4me/competitions-status`, { competitionID, published });
        return response.data;
    } catch (error) {
        throw error;
    }
}



//update competition
export const updatecompetition = async (competitionData: {
    competitionID: string;
    name: string;
    details : string;
}) => {
    try {
            const response = await axios.post(`/ott4me/competitions-update`, competitionData);
            return response.data;
    } catch (error) {
        throw error;
    }
}

//delete competition banner
export const deletecompetitionbanner = async (competitionID: any, competition_image_id: any) => {
    try {
        const response = await axios.post(`/ott4me/competitions/image-remove`, { competitionID, competition_image_id });
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const userentercompetition = (competitionData: {
    name: string;
    pin: string;
    mobile: string;
    CompetitionId: number;
}): Promise<any> => {
    try {
        const formData = new FormData();
        formData.append('name', competitionData.name);
        formData.append('pin', competitionData.pin);
        formData.append('mobile', competitionData.mobile);
        formData.append('competitionID', competitionData.CompetitionId.toString());

        // return axios.post('ott4me/competitions-user-create', formData);
        return axios.post('ott4me/play', formData);
    } catch (error) {
        throw error;
    }
};
export const admingetsponsors = async () => {
    try {
        const response = await axios.post('ott4me/competitions/get-all-sponsors');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const adminsponsorsupdate = (sponsorsData: {
    name: string;
    sponsor_id: string;
    description: string;
    background_color: string;
}): Promise<any> => {
    try {
        const formData = new FormData();
        formData.append('name', sponsorsData.name);
        formData.append('sponsor_id', sponsorsData.sponsor_id);
        formData.append('description', sponsorsData.description);
        formData.append('background_color', sponsorsData.background_color);
        return axios.post('ott4me/competitions/sponsors-update', formData);
    } catch (error) {
        throw error;
    }
};

export const sponsorsbannerupload = async (formData: any) => {
    try {
        const response = await axios.post('ott4me/competitions/sponsors-banner-upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Ensure the correct content type
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const competitionActive = async (competitionID: any, active: any) => {
    try {
        const response = await axios.post('/ott4me/competitions-active', {
            competitionID,
            active,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const dashboardcards = async (total_vouchers_period: string, pin_vouchers_period: string) => {
    try {
        const response = await axios.get(`/admin/ott4me/dashboard-statistics?total_vouchers_period=${total_vouchers_period}&pin_vouchers_period=${pin_vouchers_period}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const vouchercharts = async ( period: any) => {
    try {
        const response = await axios.post(`/admin/ott4me/vouchers-per-period?period=${period}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const addSegmentDesign = async (design: any) => {
    try {
        const response = await axios.post('/ott4me/competitions/segment-add', design);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateSegmentDesign = async (selectedComp: number, selectedSegment: string | undefined, design: any) => {
    try {
        const response = await axios.post(`/admin/ott/competition/${selectedComp}/segment/${selectedSegment}`, design);
        return response.data;
    } catch (error) {
        throw error;
    }
};

//spin&win wheel design
export const getCompDesign = async (selectedComp: number) => {
    try {
        const response = await axios.post(`ott4me/competitions-user-facing-wheel`, {competitionID: selectedComp});
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const get = async (path: string, config = {}) => {
    return  axios.get(path, config);
}

export const getWithBlobResponse = async (path: string) => {
    return  axios.get(path, {responseType: 'blob'});
}

export const destroy = async (path: string) => {
    return  axios.delete(path);
}

export const post = async (path: string, body : any) => {
    return  axios.post(path, body);
}

export const put = async (path: string, body : any) => {
    return  axios.put(path, body);
}



//designable pages list
export const getDesignablePages = async () => {
    try {
        const response = await axios.get(`/page-designer/designable-pages`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

//save page layout
export const savePageDesign = async (design: any) => {
    try {
        const response = await axios.post(`/page-designer/page-design`, {name: design.name,
            display_name: design.display_name,
            layout: design.layout,
            active: design.active});
        return response.data;
    } catch (error) {
        throw error;
    }
};

//page designs list
export const getPageLayouts = async () => {
    try {
        const response = await axios.get(`/page-designer/page-designs`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

//page design time component data
export const getDesignComponentData = async () => {
    try {
        const response = await axios.get(`/get-design-component`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

//get select options in design time for a component
export const getSelectOptions = async (component: string, field: string, display_column: string, query: string) => {
    try {
        const response = await axios.get(`/admin/component-configuration/${component}/${field}/input-options?filter[${display_column}]=${query}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

//get components to design with during design time
export const getDesignComponents = async () => {
    try {
        const response = await axios.get(`/admin/component-configuration`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

//save component input values
export const saveInputValues = async (component: string, input_values: string) => {
    try {
        const response = await axios.post(`/admin/component-configuration/${component}/create/input-values`, {input_values: input_values});
        return response.data;
    } catch (error) {
        throw error;
    }
};

//save component data
export const getComponentData = async (component_configuration_id: string) => {
    try {
        const response = await axios.get(`/component/${component_configuration_id}/data`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getLeagues = async () => {
    try {
        const response = await axios.get(`/football/leagues`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const competitionsEntriesPerPeriod = async (selectedComp: number, selectPeriod: string) => {
    try {
        const response = await axios.post(`/admin/competition/entries-per-period`, {competitionID: selectedComp, period:selectPeriod});
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const ott4meCompetitions = async () => {
    try {
        const response = await axios.get(`/admin/ott4me/competitions`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const ott4meSponsors = async () => {
    try {
        const response = await axios.post(`ott4me/competitions/get-all-sponsors`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const sponsorBannerUpload = async (formData: any) => {
    try {
        const response = await axios.post('/ott4me/competitions/sponsors-banner-upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Ensure the correct content type
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const mediaObjectUpload = async (formData: any) => {
    try {
        const response = await axios.post('/admin/media-object/create', formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Ensure the correct content type
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const mediaObjectUpdate = async (formData: any) => {
    try {
        const response = await axios.post('/admin/media-object/update', formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Ensure the correct content type
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const competitionCoverImage = async (formData: any) => {
    try {
        const response = await axios.post('/ott4me/competitions-type/image-upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Ensure the correct content type
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const voucherExport = async (formData:  {
    voucher_id: string,
    created_by_name: string,
    transaction_reference: string,
    sale_id: string,
    serial_number: string,
    batch: string,
    amount: string,
    status: string,
    created_at: string,
    sort: string
}) => {
    try {

        const response = await axios.get(`/admin/ott/vouchers/export?filter[voucher_id]=${formData?.voucher_id || ''}&filter[created_by_name]=${formData?.created_by_name || ''}&filter[transaction_reference]=${formData?.transaction_reference || ''}&filter[sale_id]=${formData?.sale_id || ''}&filter[serial_number]=${formData?.serial_number || ''}&filter[batch]=${formData?.batch || ''}&filter[amount]=${formData?.amount || ''}&filter[status]=${formData?.status || ''}&filter[created_at]=${formData?.created_at || ''}&sort=${formData?.sort}`, {responseType: 'blob'});

        return response;
    } catch (error) {
        throw error;
    }
};

export const couponExport = async (formData:  {
    id : string,
    created_by_name : string,
    start_date : string,
    endate : string,
    type : string,
    status : string

}) => {
    try {

        const response = await axios.get(`/admin/ott/coupons/export?filter[id]=${formData?.id || ''}&filter[created_by_name]=${formData?.created_by_name || ''}&filter[start_date]=${formData?.start_date || ''}&filter[endate]=${formData?.endate || ''}&filter[type]=${(formData?.type !== 'all' && formData?.type) ? formData?.type : ''}&filter[status]=${(formData?.status !== 'all' && formData?.status) ? formData?.status : ''}`, {responseType: 'blob'});

        return response;
    } catch (error) {
        throw error;
    }
};

export const transactionExport = async (formData:  {
    type : string,
    created_by_name : string,
    start_date : string,
    endate : string,
    status : string,
    vouchers_amount : string,

}) => {
    try {

        const response = await axios.get(`/admin/transation/export?filter[type]=${formData?.type || ''}&filter[created_by_name]=${formData?.created_by_name || ''}&filter[start_date]=${formData?.start_date || ''}&filter[endate]=${formData?.endate || ''}&filter[status]=${formData?.status || ''}&filter[vouchers.amount]=${formData?.vouchers_amount || ''}`,{responseType: 'blob'});

        return response;
    } catch (error) {
        throw error;
    }
};

export const providerExport = async (formData:  {
    providerID : string,
    providerName : string,
    keywords : string,
    status : string,
    published : string,
    sort: string
}) => {
    try {

        const response = await axios.get(`/admin/get-all-providers/export?filter[providerID]=${formData?.providerID}&filter[providerName]=${formData?.providerName}&filter[keywords]=${formData?.keywords}&filter[status]=${formData?.status}&filter[published]=${formData?.published}&sort=${formData?.sort}`, {responseType: 'blob'});

        return response;
    } catch (error) {
        throw error;
    }
};

export const competitionExport = async (formData:  {
    competitionID : string,
    name : string,
    type : string,
    active : string,
    start_date : string,
    endate : string,
    entries : string,
    published : string,
    sort: string,
}) => {
    try {

        const response = await axios.get(`/admin/ott4me/get-all-competitions/export?filter[competitionID]=${formData?.competitionID}&filter[name]=${formData?.name}&filter[type]=${formData?.type}&filter[active]=${formData?.active}&filter[start_date]=${formData?.start_date}&filter[endate]=${formData?.endate}&filter[entries]=${formData?.entries}&filter[published]=${formData?.published}&sort=${formData?.sort}`, {responseType: 'blob'});

        return response;
    } catch (error) {
        throw error;
    }
};

export const userExport = async (formData:  {
    first_name : string,
    last_name : string,
    id_number : string,
    mobile : string,
    email : string,
    gender : string,
    active : string,
    sort: string
}) => {
    try {

        const response = await axios.get(`/admin/users/export?filter[first_name]=${formData?.first_name}&filter[last_name]=${formData?.last_name}&filter[id_number]=${formData?.id_number}&filter[mobile]=${formData?.mobile}&filter[email]=${formData?.email}&filter[gender]=${formData?.gender}&filter[active]=${formData?.active}&sort=${formData?.sort}`, {responseType: 'blob'});

        return response;
    } catch (error) {
        throw error;
    }
};

export const adminExport = async (formData:  {
    first_name : string,
    last_name : string,
    email : string,
    active : string,
    sort: string
}) => {
    try {

        const response = await axios.get(`/super-admin/admins/export?filter[first_name]=${formData?.first_name}&filter[last_name]=${formData?.last_name}&filter[email]=${formData?.email}&filter[active]=${formData?.active}&sort=${formData?.sort}`, {responseType: 'blob'});

        return response;
    } catch (error) {
        throw error;

    }

};

export const ottSponserExport = async (formData:  {
    sponsor_id: string,
    name: string,
    friendly_name: string,
    sort: string
}) => {
    try {

        const response = await axios.get(`/admin/sponsors/export?filter[sponsor_id]=${formData?.sponsor_id}&filter[name]=${formData?.name}&filter[friendly_name]=${formData?.friendly_name}&sort=${formData?.sort}`, {responseType: 'blob'});

        return response;
    } catch (error) {
        throw error;

    }

};

export const ottSponsorImageUpload = async (formData: any) => {
    try {
        const response = await axios.post('/admin/sponsor/image-upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const surveyBannerUpload = async (id: string, banners: any) => {
    const formData = new FormData();
    formData.append('landscape_image', banners?.landscape_image);
    formData.append('portrait_image', banners?.portrait_image);

    try {
        const response = await axios.post(`/admin/survey/${id}/upload-image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Ensure the correct content type
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const competitionBannerUpload = async (id: string, banner_type: string, banner: any) => {
    const formData = new FormData();
    formData.append('banner', banner);
    formData.append('banner_type', banner_type);

    try {
        const response = await axios.post(`/admin/competition/${id}/update-banners`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateCompetitionPrize = async (id: number, values: TempPrize) => {
    try {
        const response = await axios.post(`/admin/competition/prize/${id}/update`, values, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const profileUpload = async (formData: any) => {
    try {
        const response = await axios.post('/user/profile-upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const uploadBulkFile  = async (formData: any) => {
    try {
        const response = await axios.post('/admin/bulk-imports/vouchers', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const destroyMultiple = async (path: string, ids: React.Key[]) => {
    try {
        const response = await axios.delete(path, { data: { ids } });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const ottIssuerUpload = async (formData: any) => {
    try {
        const response = await axios.post('/admin/ott-issuer/import-csv', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const ottMerchantUpload = async (formData: any) => {
    try {
        const response = await axios.post('/admin/ott-merchant/import-csv', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};