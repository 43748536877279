import React from "react";
import { useRoutes } from "react-router-dom";
import "./App.css";
import {ConfigProvider} from "antd";
import routes from "./Routes";
import TagManager from 'react-gtm-module';
import { theme } from "./lib/theme";

const App: React.FC = () => {
    const router = useRoutes(routes)
    // const gtm_id = process.env.REACT_APP_GTM_ID

    // const tagManagerArgs = {
    //   gtmId: gtm_id
    // }
    
    // TagManager.initialize(tagManagerArgs)

  return (
    <ConfigProvider theme={theme}>
      {router}
    </ConfigProvider>
  );
};

export default App;