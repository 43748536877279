import { RouteObject } from 'react-router-dom';
import {
  LandingOne,
  LandingTwo,
  LandingThree,
  SliderProduct,
  About,
  SentEmail
} from './components'

const openRoutes: RouteObject = {
  path: '/',
  children:
  [
    {
      path: "landing-page-one",
      element: <LandingOne />
    },
    {
      path: "landing-page-two",
      element: <LandingTwo />
    },
    {
      path: "landing-page-three",
      element: <LandingThree />
    },
    {
      path: "mail-info",
      element: <SentEmail />
    },
    {
      path: "about",
      element: <About />
    },
    {
      path: "product",
      element: <SliderProduct />
    },
  ]
}

export { openRoutes };
